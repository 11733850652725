import { graphql } from 'gatsby'
import {
  Link,
  Trans,
  useI18next,
  useTranslation,
} from 'gatsby-plugin-react-i18next'
import React from 'react'

import '../../styles/global.css'
import { ContentMain } from '../../components/common/content-main'
import { ContentSubPage } from '../../components/common/content-sub-page'
import { HeaderTitle } from '../../components/common/header-title'
import { CookiesBox } from '../../components/cookies-box'
import { Footer } from '../../components/footer'
import { Navigation } from '../../components/navigation'
import { SEO } from '../../components/seo'

const CookiesPolicy = () => {
  const { language } = useI18next()
  const { t } = useTranslation('pages', { keyPrefix: 'terms.cookiesPolicy' })

  return (
    <>
      <SEO title={t('title')} />
      <div className="flex flex-col h-screen justify-between">
        <Navigation />
        <ContentMain>
          <HeaderTitle title={t('title')} />
          <ContentSubPage>
            <p>
              <Trans i18nKey="intro.text.0" t={t}>
                Valid from: January 01, 2020
              </Trans>
            </p>
            <p>
              <Trans i18nKey="intro.text.1" t={t}>
                Flagis s.r.o. (&quot;us&quot;, &quot;we&quot;, or
                &quot;our&quot;) uses cookies on the{' '}
                <Link language={language} to="/">
                  https://www.flagis.com
                </Link>{' '}
                website and the Flagis mobile application (the
                &quot;Service&quot;). By using the Service, you consent to the
                use of cookies.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="intro.text.2" t={t}>
                Our Cookies Policy explains what cookies are, how we use
                cookies, how third-parties we may partner with may use cookies
                on the Service, your choices regarding cookies and further
                information about cookies.
              </Trans>
            </p>
            <h3>
              <Trans i18nKey="whatAre.title" t={t}>
                What are cookies
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="whatAre.text.0" t={t}>
                Cookies are small pieces of text sent to your web browser by a
                website you visit. A cookie file is stored in your web browser
                and allows the Service or a third-party to recognize you and
                make your next visit easier and the Service more useful to you.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="whatAre.text.1" t={t}>
                Cookies can be &quot;persistent&quot; or &quot;session&quot;
                cookies. Persistent cookies remain on your personal computer or
                mobile device when you go offline, while session cookies are
                deleted as soon as you close your web browser.
              </Trans>
            </p>
            <h3>
              <Trans i18nKey="howUses.title" t={t}>
                How Flagis s.r.o. uses cookies
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="howUses.text.0" t={t}>
                When you use and access the Service, we may place a number of
                cookies files in your web browser.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="howUses.text.1" t={t}>
                We use cookies for the following purposes:
              </Trans>
            </p>
            <ul>
              <li>
                <p>
                  <Trans i18nKey="howUses.text.2" t={t}>
                    To enable certain functions of the Service
                  </Trans>
                </p>
              </li>
              <li>
                <p>
                  <Trans i18nKey="howUses.text.3" t={t}>
                    To provide analytics
                  </Trans>
                </p>
              </li>
              <li>
                <p>
                  <Trans i18nKey="howUses.text.4" t={t}>
                    To store your preferences
                  </Trans>
                </p>
              </li>
            </ul>
            <p>
              <Trans i18nKey="howUses.text.5" t={t}>
                We use both session and persistent cookies on the Service and we
                use different types of cookies to run the Service:
              </Trans>
            </p>
            <ul>
              <li>
                <p>
                  <Trans i18nKey="howUses.text.6" t={t}>
                    Essential cookies. We may use cookies to remember
                    information that changes the way the Service behaves or
                    looks, such as a user&apos;s language preference on the
                    Service.
                  </Trans>
                </p>
              </li>
              <li>
                <p>
                  <Trans i18nKey="howUses.text.7" t={t}>
                    Accounts-related cookies. We may use accounts-related
                    cookies to authenticate users and prevent fraudulent use of
                    user accounts. We may use these cookies to remember
                    information that changes the way the Service behaves or
                    looks, such as the &quot;remember me&quot; functionality.
                  </Trans>
                </p>
              </li>
              <li>
                <p>
                  <Trans i18nKey="howUses.text.8" t={t}>
                    Analytics cookies. We may use analytics cookies to track
                    information how the Service is used so that we can make
                    improvements. We may also use analytics cookies to test new
                    advertisements, pages, features or new functionality of the
                    Service to see how our users react to them.
                  </Trans>
                </p>
              </li>
            </ul>
            <h3>
              <Trans i18nKey="thirdParty.title" t={t}>
                Third-party cookies
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="thirdParty.text.0" t={t}>
                In addition to our own cookies, we may also use various
                third-parties cookies to report usage statistics of the Service,
                deliver advertisements on and through the Service, and so on.
              </Trans>
            </p>
            <h3>
              <Trans i18nKey="yourChoices.title" t={t}>
                What are your choices regarding cookies
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="yourChoices.text.0" t={t}>
                If you&apos;d like to delete cookies or instruct your web
                browser to delete or refuse cookies, please visit the help pages
                of your web browser.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="yourChoices.text.1" t={t}>
                Please note, however, that if you delete cookies or refuse to
                accept them, you might not be able to use all of the features we
                offer, you may not be able to store your preferences, and some
                of our pages might not display properly.
              </Trans>
            </p>
            <ul>
              <li>
                <p>
                  <Trans i18nKey="yourChoices.text.2" t={t}>
                    For the Chrome web browser, please visit this page from
                    Google:{' '}
                    <a href="https://support.google.com/accounts/answer/32050">
                      https://support.google.com/accounts/answer/32050
                    </a>
                  </Trans>
                </p>
              </li>
              <li>
                <p>
                  <Trans i18nKey="yourChoices.text.3" t={t}>
                    For the Internet Explorer web browser, please visit this
                    page from Microsoft:{' '}
                    <a href="http://support.microsoft.com/kb/278835">
                      http://support.microsoft.com/kb/278835
                    </a>
                  </Trans>
                </p>
              </li>
              <li>
                <p>
                  <Trans i18nKey="yourChoices.text.4" t={t}>
                    For the Firefox web browser, please visit this page from
                    Mozilla:{' '}
                    <a href="https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored">
                      https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored
                    </a>
                  </Trans>
                </p>
              </li>
              <li>
                <p>
                  <Trans i18nKey="yourChoices.text.5" t={t}>
                    For the Safari web browser, please visit this page from
                    Apple:{' '}
                    <a href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac">
                      https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac
                    </a>
                  </Trans>
                </p>
              </li>
              <li>
                <p>
                  <Trans i18nKey="yourChoices.text.6" t={t}>
                    For any other web browser, please visit your web
                    browser&apos;s official web pages.
                  </Trans>
                </p>
              </li>
            </ul>
            <h3>
              <Trans i18nKey="more.title" t={t}>
                Where can you find more information about cookies
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="more.text.0" t={t}>
                You can learn more about cookies and the following third-party
                websites:
              </Trans>
            </p>
            <ul>
              <li>
                <p>
                  <Trans i18nKey="more.text.1" t={t}>
                    AllAboutCookies:{' '}
                    <a href="http://www.allaboutcookies.org/">
                      http://www.allaboutcookies.org/
                    </a>
                  </Trans>
                </p>
              </li>
              <li>
                <p>
                  <Trans i18nKey="more.text.2" t={t}>
                    Network Advertising Initiative:{' '}
                    <a href="http://www.networkadvertising.org/">
                      http://www.networkadvertising.org/
                    </a>
                  </Trans>
                </p>
              </li>
            </ul>
            <h3>
              <Trans i18nKey="contactUs.title" t={t}>
                Contact Us
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="contactUs.text.0" t={t}>
                If you have any questions about this Cookies Policy, please
                contact us:
              </Trans>
            </p>
            <ul>
              <li>
                <Trans i18nKey="contactUs.text.1" t={t}>
                  By email: <a href="mailto:info@flagis.com">info@flagis.com</a>
                </Trans>
              </li>
              <li>
                <Trans i18nKey="contactUs.text.2" t={t}>
                  By visiting this page on our website:{' '}
                  <Link language={language} to="/contact-us">
                    https://www.flagis.com/contact-us
                  </Link>
                </Trans>
              </li>
            </ul>
          </ContentSubPage>
        </ContentMain>
        <Footer />
      </div>
      <CookiesBox />
    </>
  )
}

export default CookiesPolicy

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
